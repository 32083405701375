import time from './time.svg';
import './BtnTime.css'
export default function BtnTime() {
    return(
        <div className='CalcBtn'>
             <button className='CalcTime'> 
             <img  src={time} alt="Result" />
             23 %</button>
        </div>
    )
}
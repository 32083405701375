import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { btnReducerForward } from '../../../../redux/actions';
import rotationForward from './forward.png'
import './BtnForward.css'


const BtnForward = ({imei})=>{
    const dispatch = useDispatch();
  
    const handleClick = () => {
      dispatch(btnReducerForward(imei?.imei));
    };
  
    return (
      <div className='commandBtn'>
        <button className='BtnForward' onClick={handleClick}>
        <img  src={rotationForward} alt="Result" />
        </button>
      </div>
    );
    return({})
}

    export default BtnForward;




import { useEffect, useState } from 'react'
import percent from './percent.svg';
import './BtnPersent.css'
// import Modal from '../../../Modal/Modal'

export default function BtnPersent() {

    // const [modal, setModal] = useState(false)


    return(
        <div className='CalcBtn'>
             <button className='CalcPercent' > 
             <img  src={percent} alt="Result" />
             23 %</button>    

            {/* <Modal open={modal}>
                <h3>Инфа</h3>
                <p>Тут ввод параметра</p>
                <button onClick={() => setModal(false)}>Тут кнопка</button>                      
            </Modal>   */}
        </div>
    )
}
import measuring from './measuring.svg';
import './BtnHydro.css'
export default function BtnHydro() {
    return(
        <div className='CalcBtn'>
             <button className='CalcHydro'> 
             <img  src={measuring} alt="Result" />
             18.34 мм</button>
        </div>
    )
}
import './Checkbox.css';
import water from './water-tap.png'

export default function Checkbox() {
    return(
        <div className='commandBlockSwitch'>
                            <img  src={water} alt="Result" />
                            <input type='checkbox' id="toggleCheckbox"  className="ToggleCheckbox" />

                            <label for="toggleCheckbox">
                                    {/* <span className='slider'></span> 
                                    for="toggleCheckbox"
                                    */}
                               
                            </label>
                        </div>
    )
}
import DmList from './dmList/dmList';
import LoginPage from './LoginPage/LoginPage';


 const HomePage = () => {
 
  const token = localStorage.getItem('token');
  console.log('проверка токена');
  const links =token ?  <DmList />: <LoginPage />

   return (
    <div className="dashboard container">
      <div className="row">
      {links}
      </div>
    </div>
  );
};

export default HomePage;


///////  ЕСЛИ НЕТ ТОКЕНА-ПИЗДУЕМ В АВТОРИЗАЦИЮ
import  './AnglePosition.css';
import angle from '../ImgDmSumm/angle.svg'

export default function AnglePosition(props) {
   const angleCurrent=props.angleCurrent;
    return (
            <div className='angle'>   
                <img className='angleImg' src={angle} alt="Result" />
                <span>{angleCurrent+'\u00b0'}</span>
            </div>        
    )
}
import React, { useState, useEffect } from 'react';
import DmLink from './DmLink/DmLink';
import AnglePosition from './AnglePosition/AnglePosition';
import DmSignal from './DmSignal/DmSignal';
import DmStatus from './DmStatus/DmStatus';
import CircleWithLineAndTriangle from '../CircleWithLineAndTriangle';
// import BtnForward from '../../buttons/BtnForward';
// import BtnRevers from '../../buttons/BtnRevers';
// import BtnStop from '../../buttons/BtnStop';

// import YandexMap from './YandexMap';
import DmDetal from '../dmDetal';
import { useNavigate } from 'react-router-dom';

import btnSet from './ImgDmSumm/setting.png'
import circle from './ImgDmSumm/circle.svg'

import percent from './ImgDmSumm/percent.svg'
import measuring from './ImgDmSumm/measuring.svg'
import time from './ImgDmSumm/time.svg'

import classes from './DmSummary.css';

const DmSummary = ({item})=>{
  const navigate = useNavigate();
  const speedSet = item?.Speed;
  const qNorm = item?.QNorma;
  const timeMove = item?.TimeMove;
  const [showDmDetal, setShowDmDetal] = useState(false);
  const username = item?.username;  
  const signalValue = item?.Sig_Value;  
  const angleCurrent = item?.AngleCurrent;
  const status = item?.param4;
 
  const handleClick = (e) => {
    e.preventDefault();
    navigate(`/dmdetal/${item.imei}`);
    localStorage.setItem('imei', 'item.imei')
  };

    return(
      <section className='cardPivot' onClick={handleClick}>
         
      <div className='cardHeader'> 
          {/* <div> <p>поливалка</p> </div> */}
          <DmSignal signalValue={signalValue} />             
          {/* <BtnForward imei={item?.imei} />  */}
          {/* <BtnRevers imei={item?.imei} />      */}
          {/* <BtnStop imei={item?.imei} />                       */}
          <DmLink param_last_date={item?.param_last_date}/>
          {/* <div className={classes.status}> <span className={classes.link}>Offline</span> </div>    */}
          {/* <div className='btnSet'> <button><img className='btnSetImg' src={btnSet} alt="Result" /></button> </div>                */}
      </div>
      
      <div className='cardBody'>
          <div className='cardBodyCircle'> 
               {/* <div>   
                  <img className={classes.circle} src={circle} alt="Result" />
              </div>  */}
              <CircleWithLineAndTriangle data={item} />
              <AnglePosition angleCurrent={angleCurrent} />  
          </div>
      
          <div className='cardInfo'> 
                 <div className='cardInfoBlock'>
                     <div className='cardInfoName'>  
                         <h3>{item.imei_name}</h3>
                     </div>
                    <DmStatus status={status} />
                 </div> 
      
                      <div className='cardBodyParam' > 
                         <div className='cardBodyPercent'> 
                             <img className='BodyImg' src={percent} alt="Result" />
                             <span>{speedSet}</span>
                         </div>
      
                         <div className='cardBodyMeasuring'> 
                             <img className='BodyImg' src={measuring} alt="Result" />
                             <span>{qNorm} мм</span>
                         </div>
      
                         <div className='cardBodyTime'>
                             <img className='BodyImg' src={time} alt="Result" />
                             <span>{timeMove} ч</span>
                         </div>
                     </div>                       
          </div>
       </div>
       <div className='cardFooter'>
       <span>{username}</span>
      </div>
      </section>
    )
}

export default DmSummary;


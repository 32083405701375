import './DmNetwork.css'
export default function DmNetwork(props) {

    const  operator_name=props.operator_name;
    const  balans=props.balans;
    return(
        <div className="dmNetworkCard">
            <div className='dmNetworkCardHeader'> 
                <h4>Сеть</h4>
            </div>
            <div className="dmNetworkOperator">
                <span>Оператор: <span>{operator_name}</span></span>
            </div>
            <div className="dmBalanse">
                <span>Баланс: {balans}р</span>
            </div>
            <div className="dmNamberFone">
                <span>Номер sim-карты: 8908756753</span>
            </div>


        </div>
    )
}
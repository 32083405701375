import { useState } from 'react';
import './DmLink.css';

export default function DmLink ({param_last_date}) {
    let islink;
    const serverTime = new Date(param_last_date);
    const browserTime = new Date();

    const diffInMilliseconds = Math.abs(browserTime - serverTime);
    const diffInMinutes = Math.floor(diffInMilliseconds/1000);
    
    if (diffInMinutes>300)   {islink ='Offline'} 
    else {islink='Online'}
         

    return(
        <section>
            <div className={islink}> 
                <span className='linkText'>{islink}</span>
            </div>
        </section>
    )
}



import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { btnReducerRevers } from '../../../../redux/actions';
import rotationRevers from './revers.png'
import './BtnRevers.css'

const BtnRevers = ({imei})=>{
    const dispatch = useDispatch();
  
    const handleClick = () => {
      dispatch(btnReducerRevers(imei?.imei));
    };
  
    return (
      <div className='commandBtn'>
        <button className='BtnRevers' onClick={handleClick}>
        <img  src={rotationRevers} alt="Result" />
        </button>
      </div>
    );
    return({})
}

    export default BtnRevers;
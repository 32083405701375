
import './App.css';
import React, { useEffect, Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import LoginPage from './components/LoginPage/LoginPage'
import RegisterPage from './components/RegisterPage/RegisterPage';
import HomePage from './components/HomePage';
import { loginUser } from './redux/actions';
// import Navbar from './components/Navbar';
import DmDetal from './components/dmDetal';

//function App() {
class App extends Component {
  render(){  
  //const dispatch = useDispatch();

  //useEffect(() => {
    //const token = localStorage.getItem('token');
   // if (token) {
      // Если токен есть в localStorage, можно сделать запрос для проверки его валидности
      // или просто обновить состояние
   //   dispatch({ type: 'LOGIN_SUCCESS', payload: { token } });
  //  };
 // }, [dispatch]);
const token = localStorage.getItem('token');
const links =token ? <HomePage /> : <LoginPage />

  return (   
      <Router>
        <div className="App">
        <Routes >
          <Route path="/home"  element={<HomePage />} />
          <Route exact path="/" element={links} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/dmdetal/:id" element={<DmDetal />} />
        </Routes >
        </div>
      </Router>
  );
};
};

const mapStateToProps=(state)=>{
  return{
    isAuthenticated: state.auth.isAuthenticated
  }
}

export default connect(mapStateToProps)(App);

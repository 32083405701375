import axios from 'axios';

export const loginUser = (credentials) => async (dispatch) => {
  try {
    const response = await axios.post('http://185.179.188.249:5000/login', credentials);
    const { token } = response.data;
    const { id } = response.data;

    localStorage.setItem('token', token);
    localStorage.setItem('iduser', id.id);
    
    dispatch({ type: 'LOGIN_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'LOGIN_FAILURE', payload: error.message });
  }
};

export const registerUser = (credentials) => async (dispatch) => {
  try {
    const response = await axios.post('http://185.179.188.249:5000/register', credentials);

    dispatch({ type: 'REGISTER_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'REGISTER_FAILURE', payload: error.message });
  }
};

export const logOut=()=>{
  return (dispatch, getState)=>{
    localStorage.removeItem('token');
    localStorage.removeItem('iduser');
    dispatch({ type: 'LOGIN_OUT', payload: null });
  }
}

export const getDms = (userid) => {
  return async (dispatch) => {

  dispatch({ type: 'FETCH_DMSDATA_REQUEST' });
  try {
    const response = await axios.get('http://185.179.188.249:5000/api/dms',{
      params:{userid},
    });
    dispatch({ type: 'FETCH_DMSDATA_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'FETCH_DMSDATA_FAILURE', payload: error.message });
  }
};
};

export const getDmImei = (imei) => {
  return async (dispatch) => {

  dispatch({ type: 'FETCH_DMSDATA_REQUEST' });
  try {
    const response = await axios.get('http://185.179.188.249:5000/api/dmimei',{
      params:{imei},
    });

    dispatch({ type: 'FETCH_DMSDATA_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'FETCH_DMSDATA_FAILURE', payload: error.message });
  }
};
};
//команда вперед
export const btnReducerForward = (imei) => {
  return async (dispatch) => {
  dispatch({ type: 'FETCH_BTNFORWARDDATA_REQUEST' });
  try {
    const response = await axios.post('http://185.179.188.249:5000/api/btnforward',{imei});
    dispatch({ type: 'FETCH_BTNFORWARDDATA_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'FETCH_BTNFORWARDDATA_FAILURE', payload: error.message });
  }
};
};
//команда взад
export const btnReducerRevers = (imei) => {
  return async (dispatch) => {
  dispatch({ type: 'FETCH_BTNREVERSDATA_REQUEST' });
  console.log('FETCH_BTNREVERSDATA_REQUEST' );
  try {
    console.log('FETCH_BTNREVERSDATA_SUCCESS' );
    console.log(imei);
    const response = await axios.post('http://185.179.188.249:5000/api/btnrevers',{imei});
    dispatch({ type: 'FETCH_BTNREVERSDATA_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'FETCH_BTNREVERSDATA_FAILURE', payload: error.message });
    console.log('FETCH_BTNREVERSDATA_FAILURE' );
  }
};
};

//команда стоп
export const btnReducerStop = (imei) => {
  return async (dispatch) => {
  dispatch({ type: 'FETCH_BTNSTOPDATA_REQUEST' });
  try {
    const response = await axios.post('http://185.179.188.249:5000/api/btnstop',{imei});
    dispatch({ type: 'FETCH_BTNSTOPDATA_SUCCESS', payload: response.data });
  } catch (error) {
    dispatch({ type: 'FETCH_BTNSTOPDATA_FAILURE', payload: error.message });
  }
};
};
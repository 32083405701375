
import React, { useState } from 'react';
import {Link} from 'react-router-dom'
 import { useDispatch, useSelector } from 'react-redux';
 import { registerUser } from '../../redux/actions';
import './RegisterPage.css'

export default function RegisterPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');


    const [surname, setSurname] = useState();
    const [name, setName] = useState();
    const [patronymic, setPatronymic] = useState();
    const [passwordFirst, setPasswordFirst] = useState();
    const [passwordSecond, setPasswordSecond] = useState();
     const dispatch = useDispatch();
     const error = useSelector(state => state.auth.error);

    const hendelClick=(e)=>{
         e.preventDefault();
         dispatch(registerUser({ username, password }));
        console.log(surname);
        console.log(name);
        console.log(patronymic);
        console.log(passwordFirst);
        console.log(passwordSecond);
    }
    return(
       
         <form className='RegisterPage'> 
         <div className='RegisterCard'> 
             <div className='RegisterCardTitle'>
                 <h2 >Регистрация</h2>
             </div> 
             <div className='RegisterCardUser'> 
                 <label className='RegisterCardLable'>Пользователь:</label>
                 <input type='text'className='RegisterCardInput'
                 value={username}
                 onChange={(e)=>setUsername(e.target.value)}></input> 
             </div>
             <div className='RegisterCardUser'> 
                 <label className='RegisterCardLable'>Фамилия:</label>
                 <input type='text'className='RegisterCardInput'
                 value={surname}
                 onChange={(e)=>setSurname(e.target.value)}></input> 
             </div>
             <div className='RegisterCardUser'> 
                 <label className='RegisterCardLable'>Имя:</label>
                 <input type='text'className='RegisterCardInput'
                 value={name}
                 onChange={(e)=>setName(e.target.value)}></input> 
             </div>
             <div className='RegisterCardUser'> 
                 <label className='RegisterCardLable'>Отчество:</label>
                 <input type='text'className='RegisterCardInput'
                 value={patronymic}
                 onChange={(e)=>setPatronymic(e.target.value)}></input> 
             </div>
             <div className='RegisterCardPassword'> 
                 <label className='RegisterCardLable'>Пароль:</label>
                 <input type='text' className='RegisterCardInput'
                 value={passwordFirst}
                 onChange={(e)=>setPasswordFirst(e.target.value)}></input> 
             </div>
             <div className='RegisterCardPassword'> 
                 <label className='RegisterCardLable'>Повторите пароль:</label>
                 <input type='text' className='RegisterCardInput'
                 value={passwordSecond}
                 onChange={(e)=>setPasswordSecond(e.target.value)}></input> 
             </div> 
             <div className='RegisterCardPassword'> 
                 <label className='RegisterCardLable'>ПАРОЛЬ:</label>
                 <input type='text' className='RegisterCardInput'
                 value={password}
                 onChange={(e)=>setPassword(e.target.value)}></input> 
             </div>         
             <div className='RegisterCardButton'> 
                 <Link className='RegisterCardLink' to="/">Назад</Link>
                 <button className='RegisterCardBtnInp'
                 onClick={hendelClick}>Зарегистрироваться</button> 
                 
             </div>
             {error && <p>{error}</p>}
         </div>
     </form>
    )
}
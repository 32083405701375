import React, { useState } from 'react';
import {Link} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../redux/actions';
import './LoginPage.css'

export default function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const error = useSelector(state => state.auth.error);

  const hendelClick = (e) => {
    e.preventDefault();
    dispatch(loginUser({ username, password }));
  };

  return (
    <form className='loginPage'> 
    <div className='loginCard'>
      <div className='loginCardTitle'>
        <h2 >Авторизация</h2>
      </div> 
      
      <div className='loginCardRoot'> 
        <label className='loginCardLable'>Логин:</label>
        <input type='text'className='loginCardInput' 
        value={username}
        onChange={(e)=>setUsername(e.target.value)}></input> 
      </div>
      <div className='loginCardPassword'> 
        <label className='loginCardLable'>Пароль:</label>
        <input type='text' className='loginCardInput'
        value={password}
        onChange={(e)=>setPassword(e.target.value)}></input> 
      </div>
      <div className='loginCardButton'> 
        <Link className='loginCardLink' to="/register">Регистрация</Link>
        <button className='loginCardBtnInp'
        onClick={hendelClick}>Вход</button>   
      </div>
        {error && <p>{error}</p>}     
    </div>
    </form>
      );
};

import DmSummary from '../dmSummary/dmSummary';
import {connect} from 'react-redux';
import { getDms } from '../../redux/actions';
import React, {useEffect,useState} from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import './dmList.css'
import magnifier from "./magnifier.svg"
import plus from "./plus.png"


const DmList = ({ data, loading, error, getDms})=>{
  const [currentTime, setCurrentTime] = useState(new Date());
  const navigate = useNavigate();
  const token = localStorage.getItem('token'); // Предположим, что токен хранится в localStorage
  const iduser = localStorage.getItem('iduser'); 
  const [input,setInput] =useState('');
  useEffect(() => {
            if (token === null) {
              navigate('/'); // Перенаправление на страницу авторизации
            }
          }, [token, navigate]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getDms(iduser);
    }, 1000);
    // Очищаем интервал при размонтировании компонента
    return () => clearInterval(intervalId);
    }, [getDms,iduser]);

   
      console.log('загрузка dmlist');
        console.log(data);
        console.log(iduser);
        console.log(data.username);
        const username =data[0] ?  data[0].username: '';
        return(
          <>
            <Navbar user={username}/>
            <div className='DmList'> 
              <div className='DmListCard'> 
                  <div className='DmListHeader'> 
                    <div className='DmListName'> 
                      <h2>Оборудование<span>{data.length}</span></h2> 
                      <div className='DmListAdd'>
                          <button className="DmlistBtn"> 
                              <img src={plus} alt="Result" className='DmListQuestImgAdd' />
                          Добавить</button>                  
                      </div>
                    </div>
                  <div className='DmListQuest'> 
                      
                      <input type='text' className='DmListInput' 
                        value={input}
                        onChange={(e)=>setInput(e.target.value)}
                      /> 
                      <img src={magnifier} alt="Result" className='DmListQuestImgMag'/>
                  </div>
                 </div> 
                  <div className='DmListCardPivot'>
                      
                        {data && data.filter((item) =>
                          item.imei_name.toLowerCase().includes(input.toLowerCase())
                        ).map(item=>{
                            return(
                          <DmSummary item={item} key={item.id}/>
                            )
                        })}
                      </div>
                </div>
              </div>
                  
          </>

        )
    }

    const mapStateToProps = state => ({
    loading: state.dm.loading,
    data: state.dm.data,
    error: state.dm.error,
     });
  
    const mapDispatchToProps = {
    getDms
    };

    
    export default connect(mapStateToProps,mapDispatchToProps)(DmList);
import React, {useEffect,useState} from 'react';
import { useParams, useNavigate} from "react-router-dom";
import {connect} from 'react-redux';
import { getDmImei } from '../redux/actions';
// import CircleWithLineAndTriangle from './CircleWithLineAndTriangle';
import DmCommands from './DmDetals/DmCommands/DmCommands';
import DmDetalsHeader from './DmDetals/DmDetalsHeader/DmDetalsHeader';
import DmInfo from './DmDetals/DmInfo/DmInfo';
import DmNetwork from './DmDetals/DmNetwork/DmNetwork';
import Navbur from './Navbar/Navbar';
import './DmDetals/DmDetals.css'
import YandexMap from './YandexMap';

function withParams(Component) {
  return props => <Component {...props} 
  params={useParams()} 
  navigate={useNavigate()}
  />;
}

const DmDetal = ({data,getDmImei}) => {
    console.log(data); 
  //  console.log({data}); 
  // Получаем параметр из URL
  const { id } = useParams();
  const imei=id;
  const navigate = useNavigate();
  const token = localStorage.getItem('token'); // Предположим, что токен хранится в localStorage
  const iduser = localStorage.getItem('iduser'); 
  const [loading, setLoading] = useState(true);
  useEffect(() => {
            if (token === null) {
              navigate('/'); // Перенаправление на страницу авторизации
            }
          }, [token, navigate]);

  useEffect(() => {
            const intervalId = setInterval(() => {
              getDmImei(imei);
            }, 1000);
            // Очищаем интервал при размонтировании компонента
            return () => clearInterval(intervalId);
            
            }, [getDmImei,imei]);
         
  return (

    <>
   
    <Navbur user={data[0]?.username} />
    <div className='dmDetalsCard'> 
     <DmDetalsHeader imei_name={data[0]?.imei_name} />
     <DmInfo 
        data = {data[0]}
      />
     <DmCommands imei={imei} />
     <DmNetwork
     operator_name={data[0]?.operator_name}
     balans={data[0]?.balans} />
    </div>
   
    <div>
      <h1>Карта Яндекса с кругом</h1>
      <YandexMap data = {data[0]}/>
    </div>
    </>

  );
};

const mapStateToProps = state => ({
  loading: state.dm.loading,
  data: state.dm.data,
  error: state.dm.error,
   });

  const mapDispatchToProps = {
    getDmImei
  };

export default connect(mapStateToProps,mapDispatchToProps)(DmDetal);
import BtnForward from '../Buttons/BtnForward/BtnForward';
import BtnRevers from '../Buttons/BtnRevers/BtnRevers';
import BtnStop from '../Buttons/BtnStop/BtnStop';
import Checkbox from '../Checkbox/Checkbox';
import BtnPersent from '../BtnCalc/BtnPersent/BtnPersent';
import BtnHydro from '../BtnCalc/BtnHydro/BtnHydro';
import BtnTime from '../BtnCalc/BtnTime/BtnTime';
import './DmCommands.css'


export default function DmCommands(imei) {
    return(
        <div className='dmDetalsCommand'> 
            <div className='commandHeader'>
                <h4>Управление</h4> 
            </div>
            <div className='commandBlock'>
                <BtnRevers imei={imei}/>
                <BtnStop imei={imei} />
                <BtnForward imei={imei}/> 
                <Checkbox /> 
            </div>
            <div className='commandRegulation'>
            <BtnPersent />
            <BtnHydro />
            <BtnTime />                     
            </div>
        </div>
    )
}
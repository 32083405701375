import AnglePosition from "../../dmSummary/AnglePosition/AnglePosition";
import CircleWithLineAndTriangle from "../../CircleWithLineAndTriangle";
import DmLink from "../../dmSummary/DmLink/DmLink";
import DmSignal from "../../dmSummary/DmSignal/DmSignal";
import DmStatus from "../../dmSummary/DmStatus/DmStatus";
import './DmInfo.css'

export default function DmInfo(props) {
    
    console.log(props);

    return(

        <div className="dmCardInfo">
            <div className="cardInfoHeader">
                <DmSignal signalValue={props.data?.Sig_Value} />
                <DmLink param_last_date={props.data?.param_last_date} />
            </div>
            <div className="cardInfoBody">
                <div className="cardInfoBodyCircle">
                    <CircleWithLineAndTriangle data={props?.data}/>
                </div>
                <div className="InfoBodyBlock">
                    <DmStatus status={props.data?.status} />
                    <div className="InfoParam"> 
                    <AnglePosition angleCurrent={props.data?.AngleCurrent} />
                    <div>P: 7,9 Бар</div>
                    </div>
                </div>
            </div>
        </div>

    )
}
import React, { useState } from 'react'
import back from '../nazad.png'
import mapBtn from '../mishen.png'
import './DmDetalsHeader.css'


export default function DmDetalsHeader(props) {
    const imei_name = props.imei_name;

    const arOptions = ['Управление', 'Настройка', 'Барьеры', 'Пушки'];
    const [value, setValue] = useState('');
 
    const options = arOptions.map((text, index) => {
        return <option key={index} value={index}>{text}</option>;
       
    });
    return(

        <>
            <div className="detalsHeader">
                <div className="detalsHeaderName">
                   <h2> {imei_name}</h2>
                </div>
                <div className="headerNavigator"> 
                    <div className="headerNavigatorBack">
                    <button> <img src={back} alt="Result" /> </button>
                     </div>
                    <div className="headerNavigatorSelect">
                        <select value={value} onChange={(event) => setValue(event.target.value)}>
                            {options}
                        </select>
                        <p>Выбрана опция: {arOptions[value]}</p>
                    </div>
                      <div className="headerNavigatorMap">
                      <button> <img src={mapBtn} alt="Result" /> </button>
                      </div>      
                        
                </div>
            </div>

        </>
    )
}
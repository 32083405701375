const initialState = {
    isAuthenticated: false,
    token: null,
    error: null,
    id: null
  };
  
  const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'LOGIN_SUCCESS':
        return {
          ...state,
          isAuthenticated: true,
          token: action.payload.token,
          error: null,
          id: action.payload.id
        };
        case 'LOGIN_OUT':
          return {
            ...state,
            isAuthenticated: false,
            token: action.payload,
            error: null,
          };
      case 'LOGIN_FAILURE':
        return {
          ...state,
          error: "ошибка авторизации, неправильно введено имя или пароль",
        };
      case 'REGISTER_SUCCESS':
        return {
          ...state,
          error: null,
        };
      case 'REGISTER_FAILURE':
        return {
          ...state,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default authReducer;
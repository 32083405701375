import React, { useEffect, useRef } from 'react';
import { YMaps, Map, Circle } from 'react-yandex-maps';
import CircleWithLineAndTriangle from './CircleWithLineAndTriangle';

const YandexMap = ({ data }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current;
      // Вы можете добавить дополнительные настройки карты здесь
    }
  }, []);

  if (!data || !data.PositionCShr || !data.PositionCDl) {
    return <div>Нет данных для отображения карты</div>;
  }

  return (
    <YMaps
      query={{
        apikey: 'd3e7e816-8a75-4745-a437-18206d135c2b', // Используйте переменную окружения или конфигурацию
        lang: 'ru_RU',
      }}
    >
      <Map
        defaultState={{
          center: [data.PositionCShr, data.PositionCDl], // Координаты центра круга
          zoom: 10,
        }}
        width="100%"
        height="400px"
        instanceRef={mapRef}
      >
        <Circle
          geometry={[
            [data.PositionCShr, data.PositionCDl], // Координаты центра круга
            10000, // Радиус круга в метрах
          ]}
          options={{
            iconLayout: 'default#imageWithContent',
            iconImageHref: '',
            iconImageSize: [32, 32],
            iconImageOffset: [-16, -16],
            iconContentLayout: function() {
              return <CircleWithLineAndTriangle data={data} />;
            },
          }}
        />
      </Map>
    </YMaps>
  );
};

export default YandexMap;
import './DmSignal.css';

export default function DmSignal(props) {

const isSignal = props.signalValue; 
let signalLevel;
let color;

if (isSignal>=1 && isSignal<=9) {signalLevel=1; color='red';} 
else if (isSignal>=10 && isSignal<=14)  {signalLevel=2; color='#f0b410';} 
else if (isSignal>=15 && isSignal<=25) {signalLevel=3;  color='#f0b410';} 
else if (isSignal>=26 && isSignal<=32)  {signalLevel=4; color='#0ca32a'}

    return(
        <div className='signal'> 
            
            <div className='signalValue'>
                <div className='bar' 
                    style={(signalLevel>=1) ? {backgroundColor:color, height:'25%'} : {height:'0%'}}></div>
                <div className='bar'
                    style={(signalLevel>=2) ? {backgroundColor:color, height:'50%'} : {height:'0%'}}></div>
                <div className='bar'
                    style={(signalLevel>=3) ? {backgroundColor:color, height:'75%'} : {height:'0%'}}></div>
                <div className='bar'
                    style={(signalLevel>=4) ? {backgroundColor:color, height:'100%'}: {height:'0%'}}></div>
                </div>
        </div> 
    )
}
import {connect} from 'react-redux';
import {logOut} from '../../redux/actions';
import './Navbar.css'
import logo from './logo.svg'
import logout from './logout.png'

 const Navbar=(props)=>{
   const username = props.user;
      
    return(
        
        <div className='navbar'>
            <div className='navbarLogo'>
                <img src={logo} alt="Result" className='navbarLogoImg'/> 
                <h3>dm-control.ru</h3>
            </div>
            <div className='navbarBlock'> 
                <div className='navbarInfo'> 
                    <div className='navbarInfoRole'>
                        <h4>User:</h4>
                    </div>
                    <div className='navburInfoUsername'>                       
                        {username}
                    </div> 
                </div> 
                 <div className='navburOut'> 
                <button className='navbarBtnOut' onClick={props.logOut}>
                <img src={logout} alt="Result" className='navbarLogout'/> 
                    </button>
                </div>   
            </div>
        </div>
        
    )
}

const mapDipatchToProps=(dispatch)=>{
    return{
        logOut:()=>dispatch(logOut())
    }
}
export default connect(null,mapDipatchToProps)(Navbar);
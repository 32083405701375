import  './DmStatus.css';

export default function DmStatus(props) {

    const checkBit = (num, bitPosition) => {
        return (num & (1 << bitPosition)) !== 0;
      };

    const forward=checkBit(props?.status, 6);
    const revers=checkBit(props?.status, 4);
    const nowater=checkBit(props?.status, 3);

    let isDmStatus='';

    if (forward && !nowater) isDmStatus="Движение вперед c водой";
    if (revers && !nowater) isDmStatus="Движение назад c водой";
    if (forward && nowater) isDmStatus="Движение вперед без воды";
    if (revers && nowater) isDmStatus="Движение назад без воды";
    if (!forward && !revers)  isDmStatus="Остановлена"
    // isDmStatus="Движение вперед c воды";
    return(
        <>
        <div className='cardInfoStateDm'> 
            <span>{isDmStatus}</span>
        </div>
        </>
    )
}
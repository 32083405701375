import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { btnReducerStop } from '../../../../redux/actions';
import stop from './stop.png'
import './BtnStop.css'

const BtnStop = ({imei})=>{
    const dispatch = useDispatch();
  
    const handleClick = () => {
      dispatch(btnReducerStop(imei?.imei));
    };
  
    return (
      <div className='commandBtnRed'>
        <button className='BtnStop' onClick={handleClick}>
        <img  src={stop} alt="Result" />
        </button>
      </div>
    );
    return({})
}

    export default BtnStop;